/* nav {
    overflow-y: scroll;
    overflow-x: hidden;
  
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
    }
  
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #6e6e6e;
      border-radius: 3px;
    }
  } */

nav {
  overflow-y: scroll;
  overflow-x: hidden;
}
nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
nav::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
nav::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  border-radius: 3px;
}
