.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
    max-height: fit-content !important;
}

.MuiDataGrid-cell {
    height: unset !important;
    max-height: 100% !important;
    overflow: auto;
    white-space: initial !important;
    line-height: 20px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

/* https://github.com/mui/mui-x/issues/417 */